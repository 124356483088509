<template lang="html">
    <div class="settingContent">
		<div class="handleBox flex flex-jc-sb flex-ai-c">
	       <h3 style="">{{$route.name}}</h3>
	        <div class="handlebox flex flex-ai-c">
	            <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="edit()">新建</el-button>
	        </div>
		</div>
        <div class="contentBox" style="width:100%;padding:0 40px">
			<el-table :data="list" size="medium" :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" :max-height="tableHeight">
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="time_month" label="时长(月)"></el-table-column>
                <el-table-column prop="_money" label="费用">
                    <template slot-scope="scope">
                        原价：{{scope.row.fee_old}}元，现价：{{scope.row.fee_now}}元
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
						<el-button-group>
							<el-button type="primary" plain size="mini" @click="edit(scope.row)">编辑</el-button>
							<el-button size="mini" type="primary" plain @click="del(scope.row.id,scope.row.name)">删除</el-button>
						</el-button-group>
                    </template>
                </el-table-column>
            </el-table>
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <win :title="title" v-if="winIsShow" @winClose="winIsShow=false" @winEnter="submit">
            <el-form ref="edit" :rules="rules" :model="formData" label-width="80px">
                <el-form-item label="名称" prop="name">
                    <el-input placeholder="请输入服务费名称" v-model="formData.name"></el-input>
                </el-form-item>
                <el-form-item label="时长" prop="time_month">
                    <el-input placeholder="请输入服务费时长（月）" v-model="formData.time_month" @keyup.native="number"></el-input>
                </el-form-item>
                <el-form-item label="原价" prop="fee_old">
                    <el-input placeholder="请输入服务费原价金额" v-model="formData.fee_old"  oninput="value=value.replace(/[^\d.]/g,'')" @change="changePrice(formData.fee_old,'fee_old')"></el-input>
                </el-form-item>
                <el-form-item label="现价" prop="fee_now">
                    <el-input placeholder="请输入服务费现价金额" v-model="formData.fee_now"  oninput="value=value.replace(/[^\d.]/g,'')" @change="changePrice(formData.fee_now,'fee_now')"></el-input>
                </el-form-item>
            </el-form>
        </win>
    </div>
</template>

<script>
import win from "../../components/win.vue";
export default {
    components: {
        win
    },
    data: function() {
        return {
            search: {
                searchValue: '', //搜索值
            },
            value: {
                name: ''
            },
            title: '添加服务费',
            winIsShow: false,
            formData: {
                money: ''
            },
            tableHeight: 660,
            // 表单验证
            rules: {
                name: [{
                    required: true,
                    message: '请输入服务费名称',
                    trigger: 'change'
                }],
                time_month: [{
                    required: true,
                    message: '请输入服务费时长',
                    trigger: 'change'
                }
				// , {
                //     pattern: /^[1-9][0-2]*$/,
                //     message: '请输入正确月份',
                //     trigger: 'blur'
                // }
				],
                fee_old: [{
                    required: true,
                    message: '请输入服务费原价金额',
                    trigger: 'change'
                }],
                fee_now: [{
                    required: true,
                    message: '请输入服务费现价金额',
                    trigger: 'change'
                }],
            },
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted: function() {
        this.init();
    },
    methods: {
        init: function() {
            this.autoHeight();
            this.getList();
        },
        changePrice: function(t, field) {
            // console.log(field)
            var _t = parseFloat(t);
            _t = _t.toFixed(2);
            if (t != _t) this.$set(this.formData, field, _t);
        },
        //自动高度
        autoHeight: function() {
            var duoyu = 60 + 70 + 61 + 30 + 65 + 15 + 40;
            this.tableHeight = document.documentElement.clientHeight - duoyu;
            window.onresize = () => {
                this.tableHeight = document.documentElement.clientHeight - duoyu;
            }
        },
        number() { //验证正整数
            this.formData.duration = this.formData.duration.replace(/[^\.\d]/g, '');
            this.formData.duration = this.formData.duration.replace('.', '');
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
        //添加或修改
        edit: function(detail = false) {
            if (detail) {
                this.formData = JSON.parse(JSON.stringify(detail));
                this.title = '编辑服务费';
            } else {
                this.formData = {};
                this.title = '添加服务费';
            }
            this.winIsShow = true;
        },
        // 获取列表
        getList() {
            this.http.post('/admin.sysFee/index', {
                curr: this.curr,
                row: this.row,
            }).then(res => {
                this.list = res.list;
                this.curr = res.curr;
                this.pages = res.pages;
                this.count = res.count;
            });
        },
        // 删除
        del: function(id, name) {
            this.$confirm('确定删除' + name + '吗?', '删除提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var apiUrl = "/admin.sysFee/delete";
                this.http.post(apiUrl, {
                    id
                }).then(res => {
                    this.getList();
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 提交
        submit() {
            // 验证
            this.$refs['edit'].validate((valid) => {
                if (valid) {
                    if (parseFloat(this.formData.fee_now) < parseFloat(this.formData.fee_old)) {
                        var apiUrl = "/admin.sysFee/save";
                        this.http.post(apiUrl, this.formData).then(res => {
                            this.winIsShow = false;
                            this.$message({
                                message: '提交成功',
                                type: 'success'
                            });
                            this.getList();
                        });
                    } else {
                        this.$message.error('请输入比原价低的现价');
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="css" scoped>
</style>
